<template>
    <div class="container">
        <h1>{{ $t('companyBrokers.heading') }}</h1>
        <p>{{ $t('companyBrokers.subheading') }}</p>

        <i18n path="companyBrokers.skipNotice" tag="p" class="alert alert-primary">
            <a href="" @click.prevent="$emit('next')" target="_blank">{{ $t('companyBrokers.skipButton') }}</a>
        </i18n>

        <div
            v-if="peopleToList.length > 0"
            class="mb-5"
        >
            <person-list
                :people="peopleToList"
                @edit="onEditCompanyBroker"
                @delete="onDeleteCompanyBroker"
            />

            <div
                v-if="!showForm"
                class="mt-4"
            >
                <b-btn
                    variant="primary"
                    @click.prevent="onAddNewPerson"
                >
                    <b-icon icon="plus" />{{ $t('companyOwners.addPerson') }}
                </b-btn>
            </div>
        </div>

        <div
            v-if="showForm"
            class="p-4 bg-white border-light rounded shadow"
        >
            <loader-overlay
                :loading="companyBrokersSaving"
            >
                <company-person-form
                    :defaults="editedCompanyBroker || {}"
                    :date-picker-lang="datePickerLang"
                    :show-validation="touchForm"
                    @submit="onSubmitCompanyBroker"
                    @cancel="onCancel"
                />
            </loader-overlay>
        </div>
    </div>
</template>

<script>
import ViewBase from '../ViewBase.vue'
import CompanyPersonForm from '../../../../Components/CompanyPersonForm.vue'
import PersonList from '../../../../Components/PersonList.vue'

export default {
    name: 'CompanyBrokers',
    extends: ViewBase,
    components: {
        CompanyPersonForm,
        PersonList
    },
    data () {
        return {
            showForm: !(this.sessionData && this.sessionData.clientType === 'PO' ? Array.isArray(this.sessionData.companyPeople) && this.sessionData.companyPeople.length > 0 : true),
            editedCompanyBroker: null,
            companyBrokersSaving: false,
            touchForm: false
        }
    },
    computed: {
        companyPeople () {
            return (this.sessionData && this.sessionData.companyPeople) || []
        },
        companyBrokers () {
            return this.companyPeople.filter((item) => !item.executive)
        },
        syntheticPerson () {
            let syntheticPerson = null
            if (this.sessionData && this.sessionData.clientType !== 'PO') {
                const basicData = this.sessionData.basicData || {}
                syntheticPerson = {
                    _id: basicData._id,
                    name: basicData.name,
                    surname: basicData.surname,
                    noRc: basicData.noRc,
                    rc: basicData.rc,
                    birthDate: basicData.birthDate,
                    jobFunction: 'fyz. osoba',
                    executive: true
                }
            }
            return syntheticPerson
        },
        peopleToList () {
            return [this.syntheticPerson, ...this.companyPeople].filter(item => item)
        }
    },
    watch: {
        companyBrokers (newValue, oldValue) {
            this.$nextTick(() => {
                if (oldValue.length > 0 && newValue.length === 0) {
                    this.showForm = true
                }
                if (oldValue.length < newValue.length) {
                    this.showForm = false
                }
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        if (this.editedCompanyBroker) {
            this.$store.commit('setCompanyPersonUploads', [])
        }
        next()
    },
    mounted () {
        this.$serverValidator.addHandler('companyBrokers', this.onServerValidation)
        this.$store.commit('setFormData', { name: 'companyBrokers', content: this.companyBrokers })
    },
    beforeDestroy () {
        this.$serverValidator.removeHandler('companyBrokers', this.onServerValidation)
        this.touchForm = false
    },
    methods: {
        async onServerValidation (isValid, personId) {
            this.touchForm = false
            if (!isValid) {
                this.$notify.error(this.$t('errors.someDataIsMissing'))
                const person = this.companyBrokers.find(item => item._id === personId)
                this.onEditCompanyBroker(person || {})
                this.touchForm = true
            }
        },
        async onSubmitCompanyBroker (data) {
            this.companyBrokersSaving = true
            data.broker = true
            data.executive = false
            const api = this.$api.clientSessionCompanyPeople
            try {
                const apiCall = (id, data) => (id) ? api.update(id, data) : api.create(data)
                await apiCall(data._id || null, data)
                this.editedCompanyBroker = null
                this.showForm = false
                this.$store.commit('setCompanyPersonUploads', [])
                this.$store.commit('setDataHash', { name: 'companyPersonOld', hash: null })
                this.$store.commit('setDataHash', { name: 'companyPerson', hash: null })
                this.$emit('reload')
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.sessionUpdateFailed'))
            } finally {
                this.$nextTick(() => {
                    this.companyBrokersSaving = false
                })
            }
        },
        onAddNewPerson () {
            this.onCancel()
            this.showForm = true
        },
        onCancel () {
            this.showForm = false
            this.editedCompanyBroker = null
            this.touchForm = false
            const savedUploads = this.companyPeople.map(item => item.uploads).flat()
            const storedUploads = this.$store.state.uploads.companyPerson || []
            for (const upload of storedUploads) {
                if (!savedUploads.find(item => item.upload === upload.upload)) {
                    this.$api.clientSessionUploads.delete(upload.upload)
                }
            }
            this.$store.commit('setCompanyPersonUploads', [])
        },
        onEditCompanyBroker (person) {
            this.onCancel()
            this.$nextTick(() => {
                this.editedCompanyBroker = { ...person }
                this.showForm = true
            })
        },
        onDeleteCompanyBroker (person) {
            this.companyBrokersSaving = true
            this.$api.clientSessionCompanyPeople.delete(person._id)
                .then(() => {
                    this.$emit('reload')
                }).catch((error) => {
                    console.error(error.message)
                }).finally(() => {
                    this.$nextTick(() => {
                        this.companyBrokersSaving = false
                    })
                })
        }
    }
}
</script>
